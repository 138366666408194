import { useState } from "react";
import { toast } from "react-hot-toast"

function Verify() {
  const [name, setName] = useState("");
  const handleSubmit = (event) => {
    event.preventDefault();
    if(name.includes("15S446G7734") || name.includes("2165GR633W1") ||name.includes("32A1545WN631")||name.includes("6W44XN4749R4")||name.includes("842154AOF894") || name.includes("897FVHJ1563T9") || name.includes("87IOHERHF0") || name.includes("87EGHIOVF4") || name.includes("89778KJG34") || name.includes("879HJTR34")|| name.includes("869Y89BJ3")|| name.includes("654GNDE645") || name.includes("89498NJB34")|| name.includes("89I8709ER") || name.includes("654GEIOHG5") || name.includes("876798BKJB") || name.includes("8978JDFDF43")|| name.includes("89634BKJB34") || name.includes("9070JL453") || name.includes("09HK4FDH34") || name.includes("89459NKJN34") || name.includes("889454UDF")|| name.includes("8034BJL4GF") || name.includes("3HKLN436HI")|| name.includes("88989UND54")|| name.includes("KJBJ5T489R") || name.includes("BKJ450834") || name.includes("8907NKT5R") || name.includes("H56U5UTNM")|| name.includes("867N6R63W1")|| name.includes("87959JN5HBE")|| name.includes("67NJRBNRB")|| name.includes("JN56UE5BDNY")|| name.includes("UG54Y5BN56N")|| name.includes("M67UMUN67U")|| name.includes("8IJNNMUL")|| name.includes("BTYU7T9689")|| name.includes("56E4BN5NMU6")|| name.includes("67MIO87O87P")|| name.includes("870954KNLEG")|| name.includes("89T5NKLBR")|| name.includes("2165GR633W1")|| name.includes("879EKNLRT")|| name.includes("09UG54NKL5Y")|| name.includes("905KJLBTRH")|| name.includes("09754KHLTR")|| name.includes("8734KJNHH54")|| name.includes("897346JKY")|| name.includes("980TYKJLHUY")|| name.includes("89GT54NKLY")|| name.includes("8975YKNLHYK")|| name.includes("8095YKJLHY")|| name.includes("84TY954GMKL")|| name.includes("67NJTYU67")|| name.includes("67INJTNTYJ")|| name.includes("6786R7NJN5")|| name.includes("HNJYU67IN76")|| name.includes("BTYUIU67I87")|| name.includes("8956NJ5687")|| name.includes("04U65NNTYJ")|| name.includes("65HNB4W34M")|| name.includes("NYHJJ6KJ88")|| name.includes("96NJN6755NH")|| name.includes("976NJYUT9Y")
   || name.includes("1JG5S446G734") || name.includes("8ULK165GR633W1") ||name.includes("32A1JF5WN631")||name.includes("6DJ4X749R4")||name.includes("842G54AOF894") || name.includes("21RTYRS33W1") || name.includes("HTH65633W1") || name.includes("1591TIIOJER") || name.includes("U0934NKLTR") || name.includes("89H54NKLN")|| name.includes("89Y343THTR4")|| name.includes("8934KNL434") || name.includes("8734KLN343")|| name.includes("75YRHRTH54") || name.includes("6TRYTR6545") || name.includes("7457JTK78") || name.includes("2165GR633W1")|| name.includes("95498UI9ET") || name.includes("Y43FGHUY67") || name.includes("Y895JRY5E45") || name.includes("675EG3BV45") || name.includes("6748MTRJHGF")|| name.includes("768NJ5YE54") || name.includes("784HG3F455")|| name.includes("5687U6RHU56")|| name.includes("756HBERG56") || name.includes("U64UH56N6IU") || name.includes("8HB43W34IUO") || name.includes("H6J7RH650")|| name.includes("2165GR633W1")|| name.includes("NT8568N5YT")|| name.includes("N67I68I678")|| name.includes("H678NG5Y56")|| name.includes("H56UNJ568")|| name.includes("J67H867NM9")|| name.includes("ITYNMIOO")|| name.includes("TYBU56U8I67")|| name.includes("2165GR633W1")|| name.includes("2165GR633W1")|| name.includes("89734HLGTR")|| name.includes("U98TYNKL5Y")|| name.includes("2165GR633W1")|| name.includes("89ETIYOERBYJ")|| name.includes("89YE5KJTRH")|| name.includes("897TRKJLNY")|| name.includes("34IHTYHJU54")|| name.includes("89TRNKLY5")|| name.includes("89GTRHOJTY")|| name.includes("2165GR633W1")|| name.includes("IJOP89754")|| name.includes("KLN54Y89Y0")|| name.includes("NJT589Y546")|| name.includes("MTHJT56U54")|| name.includes("JG6THJT56UYT")|| name.includes("3HJUY986JM")|| name.includes("TYHJNU6678")|| name.includes("879IHN67U5")|| name.includes("5U5U67IM7Y")|| name.includes("564ENJ6M67")|| name.includes("587NYUOIU")|| name.includes("2165GR633W1")|| name.includes("TYNTYU666")|| name.includes("K7NYUI76IR")|| name.includes("JHTU7GH98")  )
    toast.success(`Verification successful for Certificate ID: ${name}.`);
  else
  toast.error(`Verification failed for Certificate ID: ${name}.`);
  }

  return (
    
      <form onSubmit={handleSubmit} className="flex flex-col gap-7">
      <label><p className="mb-1 text-[0.875rem] leading-[1.375rem] text-richblack-5">
      Enter your Certificate ID : <sup className="text-pink-200">*</sup>
        </p>
        <input 
          type="text" 
          value={name}
          placeholder="589R8978Y"
          className="form-style w-full"
          onChange={(e) => setName(e.target.value)}
          required
        />
      </label>
      <button
        type="submit"
        className="mt-6 rounded-[8px] bg-yellow-50 py-[8px] px-[12px] font-medium text-richblack-900"
      >
        Verify
      </button>
    </form>
  );
}

export default Verify;