import Verify from "./Verify";
const VerifyUs =()=>{
    return (
        <div className="border border-richblack-600 text-richblack-300 rounded-xl p-7 lg:p-14 flex gap-3 flex-col">
          <h1 className="text-4xl leading-10 font-semibold text-richblack-5">
                Verify Your Certificate
          </h1>
          <p className="">
          Welcome to our certificate verification portal. Ensuring the authenticity of our certificates is crucial, and we provide a simple process for verification. Follow these steps to verify your certificate:
          </p>
    
          <div className="mt-7">
            <Verify />
          </div>
        </div>
      );
}
export default VerifyUs;